import { FormControl, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from "lodash";
import { useCallback, useEffect, useState, Fragment } from 'react';
import { Field } from "react-final-form";
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { PANTile, PANTitle, SaveButton, toast } from "../../components";
import { PANChip, Row } from "../../components/FormElements";
import {PANWDSInput, PANWDSSelect, PANWDSCheckboxField, PANWDSForm, PANWDSBreadcrumbs} from "../../components/PANWDSElements";
import { dataProvider } from '../../dataProvider';
import { RouteUri } from '../../routeUri';
import { isAlphaNumeric, isRequired, maxElements, maxLength, composeValidators } from '../../utils/validate';
import {ApplicationConfigManager} from "../../types";
import { Banner, Button, Button as PANWDSButton }  from "@panwds/react-ui";
import { usePermissions, useTranslate } from '../../customHooks';
import isEmpty from "lodash/isEmpty";
import {useAppDispatch} from "../../app/hooks";
import { ReduxActions, ReduxResources } from "../../redux";
import * as DataTypes from "../../api/FwaasDataTypes";
import { useGetLinkAccountsQuery } from '../../redux/services/accounts-service';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    flexDirection: "row",
  },
  toolbar: {
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: 'end',
    '-webkit-justify-content': 'flex-end',
    alignItems: 'start',
    boxSizing: 'border-box',
    padding: '10px 0',
    minHeight: 'initial',
    backgroundColor: 'transparent'
  },
}));

const RuleStackCreate = (props: any) => {
  const classes = useStyles();
    const dispatch = useAppDispatch();
  const translate = useTranslate();
  const history = useHistory();
  const search = useLocation().search;
  const queryScope = new URLSearchParams(search).get('scope');
  const [accountChoices, setAccountChoices] = useState<any[] | undefined>(undefined);
  const [submitting, setSubmitting] = useState(false);
  const { permissions, rawPermissions } = usePermissions();
  const [nextToken, setNextToken] = useState<string | null>(null);
  const [accountList, setAccountsList] = useState<Record<string, unknown>[]>([]);

  const title = translate(`resources.ruleStacks.fields.Create.${queryScope}`);
  const requiredAccount = (accountChoices && accountChoices.length > 1 ? { validate: isRequired, required: true } : {});
  const tenantVersionV2 = ApplicationConfigManager.getInstance().getConfig().tenantVersion === "V2";
  const region = ApplicationConfigManager.getInstance().getConfig().currentRegion.RegionCode;

    const { data: accounts, error: accountsError, isLoading } = useGetLinkAccountsQuery({
            nexttoken: nextToken || '',
            maxresults: 1000,
            onboarded: true,
            describe: true,
            region: region,
        }, {
            skip: nextToken === undefined,
    });

    useEffect(() => {
        if (accounts && accounts.items) {
            setAccountsList((prevItems) => [...prevItems, ...accounts.items]);
            setNextToken(accounts?.nexttoken);
        }
    }, [accounts]);

    if (accountsError) {
        toast.error(accountsError?.error, { toastId: "firewall-edit-get-accounts" });
    }

  useEffect(() => {
    try {
        if (!isEmpty(rawPermissions)) {
            const isGfa = !isEmpty(rawPermissions.filter((element: any) => element?.Policy === "GlobalFirewallAdmin"));
            const graAccounts = rawPermissions.filter((element: any) => element?.Policy === "LocalRuleStackAdmin");
            //setAccountChoices(choices);
            if (tenantVersionV2 && isGfa) {
                dataProvider.get("accounts")
                    .then(async (response: DataTypes.IFwaasApiResponse) => {
                        //@ts-ignore
                        let ret: DataTypes.IFwaasApiResponse = {};
                        if (response?.data) {
                            let accountIds = response.data?.AccountIds as string[];
                            if (accountIds) {
                                const choices = accountIds.map((accountId: any) =>
                                    ({ text: accountId, value: accountId })
                                );
                                setAccountChoices(choices);
                            }
                        } else {
                            toast.error(response?.error, { toastId: "accounts-get" });
                        }
                        return ret;
                    })
                    .catch((e: any) => {
                        if (e?.noToast) {
                            return;
                        }
                        toast.error(e?.error, { toastId: "accounts-get" });
                    });
            } else {
                const choices = graAccounts.map((permission: any) =>
                    ({ text: permission?.AccountId, value: permission?.AccountId })
                );
                setAccountChoices(choices);
            }
        }
    } catch(e: any) {
        toast.error(e?.error, { toastId: "rulestack-create" });
    }
  }, [tenantVersionV2, rawPermissions]);

  const saveRuleStack = useCallback(
    async (values) => {
      if (!permissions?.CreateRuleStack) {
        return;
      }
      _.set(values, 'RuleStackEntry.Scope', queryScope);
      values['RuleStackEntry']['LookupXForwardedFor'] = (values?.RuleStackEntry?.LookupXForwardedFor) ?  "SecurityPolicy" : 'None';
      const tags = [...values.Tags];
      delete values.Tags;
      setSubmitting(true);
      try {
          await dispatch(ReduxActions.create({resource: ReduxResources.RULESTACK})({data: values})).unwrap()
              .then((response) => {
                  if (tags.length) { // post tags if any
                      const tagsPayload = {
                          resourceType: 'rulestacks',
                          resourceName: values.RuleStackName,
                          payload: { "Tags": tags }
                      };
                      //@ts-ignore
                      dataProvider.update("tags", tagsPayload).then(res => {
                          history.replace(RouteUri.RuleList.replace(":rulestackname", response?.data?.RuleStackName)
                              + "?scope=" + response?.data?.RuleStackEntry?.Scope);
                      }).catch((response) => {
                          history.goBack();
                          toast.error(response?.error, { toastId: "rulestack-tags-update" });
                      });
                  } else {
                      history.replace(RouteUri.RuleList.replace(":rulestackname", response?.data?.RuleStackName)
                          + "?scope=" + response?.data?.RuleStackEntry?.Scope);
                  }
              })
              .catch((response) => {
                  history.goBack();
                  toast.error(response?.error, { toastId: "rulestack-create" });
              });
        //history.goBack();
        return;
      } catch (error: any) {
        toast.error(error?.error, { toastId: "rulestack-create" });
        return;
      } finally {
        setSubmitting(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permissions]
  );

  const RulestackCreateToolbar = (toolbarProps: any) => {
    const classes = useStyles();
    return (
      <div className={classes.toolbar} >
        <PANWDSButton
          size="md"
          appearance="secondary"
          disabled={submitting}
          dataTestId="rulestack-create-cancel"
          onClick={() => {
            history.goBack();
          }}
          dataMetrics="cloudngfw-rulestack-create-cancel-button"
        >
          Cancel
        </PANWDSButton>
        <SaveButton
          appearance="primary"
          size="md"
          redirect={false}
          submitOnEnter={true}
          data-test-id="rulestack-create-submit"
          loading={submitting}
          disabled={!permissions?.CreateRuleStack}
          dataMetrics="cloudngfw-rulestack-create-save-button"
          {...toolbarProps}
        />
      </div>
    )
  };

  return (
    <>
      {/*<PANTitle divider region />*/}
      <PANTitle divider />
        <PANWDSBreadcrumbs mapping={{
            rulestacks: `${translate(`resources.ruleStacks.name`)} [ ${ApplicationConfigManager.getInstance().getConfig().currentRegion.RegionDisplayName} ]`,
            create: translate(`resources.ruleStacks.fields.Create.${queryScope}`),
        }}/>
      <PANTitle title={title} divider />

        <PANWDSForm
            onSubmit={saveRuleStack}
            toolbar={<RulestackCreateToolbar />}
            style={{ margin: 16 }}
        >
            <Fragment>
                <Grid container style={{ width: 'auto' }}>
                    <PANTile title="General" size={12}>
                        {accountList?.length === 0 &&
                            <div className='tw-pb-4'>
                                <Banner
                                    type="inline"
                                    appearance="warning"
                                    showIcon
                                    onClick={(e) => {
                                        e.preventDefault();
                                        history.push(RouteUri.AccountList)}}
                                    actions={
                                        <>
                                            <Button appearance="primary" size="sm" dataMetrics={"cloudngfw-swupgrade-notification-redirect"} onClick={(e) => {
                                                e.preventDefault();
                                                history.push(RouteUri.AccountList)}}
                                            >
                                                {translate(`resources.firewallsV2.onboardAwsAccount`)}
                                            </Button>
                                        </>
                                    }
                                >
                                    {translate(`resources.firewallsV2.onboardAwsAccountBanner`)}
                                </Banner>
                            </div>
                        }
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} className="noLeftPadding">
                                <FormControl className={classes.formControl}>
                                    <Field
                                        name="RuleStackName"
                                        // @ts-ignore
                                        component={PANWDSInput}
                                        title="Name"
                                        required
                                        inputProps={{
                                            "data-test-id": "rulestack-name-input"
                                        }}
                                        validate={composeValidators(isAlphaNumeric, isRequired, maxLength(128))}
                                        dataMetrics="rulestack-name-input"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} className="noLeftPadding">
                                <FormControl className={classes.formControl}>
                                    <Field
                                        name="RuleStackEntry.Description"
                                        // @ts-ignore
                                        component={PANWDSInput}
                                        title="Description"
                                        inputProps={{
                                            "data-test-id": "rulestack-description-input"
                                        }}
                                        validate={maxLength(512)}
                                        dataMetrics="rulestack-description-input"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        {queryScope && queryScope === "Local" && <Row>
                            <Grid item xs={12} sm={6} className="noLeftPadding">
                                <FormControl fullWidth>
                                    <Field
                                        name="RuleStackEntry.AccountId"
                                        // @ts-ignore
                                        component={PANWDSSelect}
                                        items={accountChoices}
                                        title={translate(`resources.firewallsV2.onboardedAwsAccountIds`)}
                                        dataMetrics="rulestack-accountid-select"
                                        loading={accountChoices === undefined}
                                        {...requiredAccount}
                                        defaultSingleFirst
                                    />
                                </FormControl>
                            </Grid>
                        </Row>}
                        <Row>
                            <Grid item xs={12} sm={6} className="noLeftPadding">
                                <FormControl fullWidth>
                                    <Field
                                        name="Tags"
                                        // @ts-ignore
                                        component={PANChip}
                                        options={[]}
                                        addNew
                                        addNewLabel="Add New"
                                        label={`Tags`}
                                        validate={maxElements(200)}
                                        addDirectly
                                        dataTestId="rulestack-tags-input"
                                    />
                                </FormControl>
                            </Grid>
                        </Row>
                        <Row>
                            <Grid item xs={12} sm={6} className="noLeftPadding">
                                <FormControl fullWidth>
                                    <Field
                                        name="RuleStackEntry.LookupXForwardedFor"
                                        // @ts-ignore
                                        component={PANWDSCheckboxField}
                                        defaultValue={false}
                                        label={translate(`resources.ruleStacks.fields.XFF`)}
                                        muted={translate(`resources.ruleStacks.fields.XFFLabel`)}
                                        dataMetrics="rulestack-lookup-x-forwarded-for-checkbox"
                                        type="checkbox"
                                    />
                                </FormControl>
                            </Grid>
                        </Row>
                    </PANTile>
                </Grid>
            </Fragment>
        </PANWDSForm>
    </>
  );
};

export default withRouter(RuleStackCreate);
