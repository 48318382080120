import { Button, Card, CardBody, Link, Modal, ModalBody, ModalCloseButton, ModalHeader, Subtitle } from "@panwds/react-ui";
import { useTranslate } from "../../../../customHooks";
import { ToolsIcon } from "@panwds/icons";
import { RouteUri } from "../../../../routeUri";
import { useHistory } from "react-router-dom";

const FirewallCreateNotifModal = (props: any) => {
    const translate = useTranslate();
    const history = useHistory();
    const fwDetailsRedirect = () => {
        if (props?.fwId && props?.region) {
            props?.closeModal();
            history.push(RouteUri.NGFirewallEdit
                .replace(":firewallname", props.fwId)
                + "?region=" + props.region);
        }
        return;
    };

    return (
        <Modal size="lg" confirmClose={false} onClose={props.closeModal} isOpen={props.isOpen}>
            <ModalHeader title={translate('resources.firewallsV2.fwCreating')} enableClose={true} />
            <ModalBody>
                <div className="tw-flex tw-justify-center tw-items-center tw-flex-col">
                    <div className="tw-py-4 tw-leading-relaxed">
                        <div>{translate('resources.firewallsV2.fwCreatingInfo')}</div>
                    </div>
                    <div className="tw-py-4">
                    <div className="tw-flex tw-flex-row tw-space-x-2 tw-text-sm">
                        <div><ModalCloseButton>{translate('generic.close')}</ModalCloseButton></div>
                            <div><Button appearance="primary" onClick={() => fwDetailsRedirect()}>
                                {translate('resources.firewallsV2.checkFwDetails')}
                            </Button></div>
                        </div>
                    </div>
                    </div>
                    <div className="tw-py-4">
                        <Card addClassName="tw-bg-gray-50">
                            <CardBody>
                                <div className="tw-flex tw-flex-row tw-space-x-1">
                                    <div><ToolsIcon size="xxs" /></div>
                                    <div>
                                    <Subtitle>{translate('resources.firewallsV2.fwCreatingCardTitle')}</Subtitle>
                                    </div>
                                </div>
                                <div className="tw-leading-loose tw-text-xs tw-text-gray-600">
                                    <ul style={{listStyle: "disc"}} className="tw-list-style-disc tw-pl-6">
                                        <li key={0}>{translate('resources.firewallsV2.fwCreatingCardLogProfile')}</li>
                                        <li key={1}>{translate('resources.firewallsV2.fwCreatingCardMetric')}</li>
                                        <li key={2}>{translate('resources.firewallsV2.fwCreatingCardDecryption')}</li>
                                        <li key={3}>{translate('resources.firewallsV2.fwCreatingCardTags')}</li>
                                    </ul>
                                </div>
                                <div>
                                    <Link addClassName=" tw-pl-2 tw-text-xs" href="/accounts">{translate('resources.firewallsV2.fwCreatingCardLinkAccount')}</Link>
                                </div>
                            </CardBody>
                        </Card>
                    </div>

            </ModalBody>
        </Modal>
    );
};
export default FirewallCreateNotifModal;
