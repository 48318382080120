import { useTranslate } from "../../../../customHooks";
import { useMemo, useCallback, useRef, useEffect } from "react";
import { FormLayout, Input, Checkbox, RadioGroup, SubmitButton } from "@panwds/react-form";
import { useForm, FormProvider, Controller, useWatch } from "react-hook-form";
import { isValidIPAMPoolId } from "../../../../utils/validate";
import { Button, LoadingPanel, Subtitle } from "@panwds/react-ui";
import { useUpdateFirewallMutation } from "../../../../redux/services/firewalls-service";
import { extractFieldsWithConfig } from "../../../../utils/utils";
import { isEmpty } from "lodash";
import AWSServiceIPsTableComponent from "../Edit/EgressNatComponents/AWSServiceIPsTableComponent";
import { toast } from "../../../../components";
import { FirewallUpdateBanner } from "../../FirewallUpdateBanner";
import { firewallStyles } from "../../style";

const FirewallEgressNat = (props: { firewallData: Record<string, any>; isLoading: boolean; refreshFirewallData: () => void }) => {
    const translate = useTranslate();
    const [updateFirewall] = useUpdateFirewallMutation();
    const region = useMemo(() => new URLSearchParams(location.search).get('region'), [location.search]);
    const firewallUpdating = ["CREATING", "UPDATING", "CREATE_FAIL", "DELETING"].includes(props?.firewallData?.Status?.FirewallStatus);
    const classes = firewallStyles();

    const config = { Firewall: ["FirewallId", "Region", "EgressNAT", "UpdateToken", "DeploymentUpdateToken"], Status: ["PublicIPs"] };

    const transformFormDefaultValue = useMemo(() => {
        if (isEmpty(props?.firewallData)) {
            return undefined;
        }

        const newStateWithConfig: any = extractFieldsWithConfig(props?.firewallData, config);

        const updatedFirewall = {
            ...newStateWithConfig.Firewall,
            EgressNAT: {
                ...newStateWithConfig.Firewall?.EgressNAT,
                Settings: {
                    ...newStateWithConfig.Firewall?.EgressNAT?.Settings,
                    IPPoolType: newStateWithConfig.Firewall?.EgressNAT?.Settings?.IPPoolType || 'AWSService',
                    IPAMPoolId: newStateWithConfig.Firewall?.EgressNAT?.Settings?.IPAMPoolId
                }
            },
        };

        return {
            Firewall: updatedFirewall,
            Status: {
                ...newStateWithConfig?.Status,
            }
        };
    }, [props?.firewallData]);

    const formMethods = useForm({ defaultValues: transformFormDefaultValue });

    const { watch, formState: { errors, dirtyFields }, reset } = formMethods;
    const egressNatEnable = watch("Firewall.EgressNAT.Enabled");
    const iPoolType = useWatch({ control: formMethods.control, name: "Firewall.EgressNAT.Settings.IPPoolType" });
    // Ref to store the initial IPPoolType value
    const initialIPPoolTypeRef = useRef(formMethods.getValues("Firewall.EgressNAT.Settings.IPPoolType"));

    useEffect(() => {
        initialIPPoolTypeRef.current = formMethods.getValues("Firewall.EgressNAT.Settings.IPPoolType");
    }, [transformFormDefaultValue]);

    const transformFormSubmitValue = useCallback((formData: Record<string, any>) => {
        if (isEmpty(formData)) {
            return;
        }

        const { Firewall, Status } = formData;

        let transformedData = {
            ...Firewall,
            EgressNAT: {
                ...Firewall?.EgressNAT
            }
        };

        if (Status && Status?.PublicIPs) {
            transformedData.Status = {
                PublicIPs: Status?.PublicIPs,
            };
        }

        if (!Firewall?.EgressNAT?.Enabled) {
            delete transformedData?.EgressNAT?.Settings;
            delete transformedData?.Status;
        } else if (Firewall?.EgressNAT?.Settings?.IPPoolType === 'AWSService') {
            delete transformedData?.EgressNAT?.Settings?.IPAMPoolId;
        } else if (transformedData?.Status === undefined || Firewall?.EgressNAT?.Settings?.IPPoolType !== 'AWSService') {
            delete transformedData?.Status;
        }

        return transformedData;
    }, []);

    const onSubmit = useCallback(async (data) => {
        try {
            const result = await updateFirewall({
                payload: transformFormSubmitValue(data),
                region: region || '',
            }).unwrap();

            // Refetch the latest firewall data
            await props.refreshFirewallData();

            toast.success("Firewall updated successfully");
        } catch (error: any) {
            if(error?.code === 409) {
                await props.refreshFirewallData();
            }
            toast.error(`${error?.code}: ${error?.error}`, { toastId: "update-firewall-egress-nat" });
        }
    }, [updateFirewall, transformFormSubmitValue, region, props.refreshFirewallData, reset, transformFormDefaultValue]);

    const handleCancel = () => {
        reset(transformFormDefaultValue);
    };

    const awsServiceOptions = [
        { value: 'AWSService', label: translate('resources.firewallsV2.awsService') },
        { value: 'BYOIP', label: translate('resources.firewallsV2.byoip') }
    ];

    return (
        <div className="tw-relative">
            {props?.isLoading &&
                <div className="tw-absolute tw-inset-0 tw-right-0 tw-flex tw-justify-center tw-items-center tw-backdrop-blur-sm tw-z-50" style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }}>
                    <div className="tw-w-6 tw-h-6">
                        <LoadingPanel />
                    </div>
                </div>
            }
            {firewallUpdating &&
                <FirewallUpdateBanner refresh={props.refreshFirewallData} />
            }
            <div className={classes.fwEditHeader}>{translate(`resources.firewallsV2.egressNat`)}</div>
            <div className={firewallUpdating ? classes.fwReadOnly : ""}>
                <FormProvider {...formMethods}>
                    <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                        <FormLayout>
                            <Checkbox
                                name="Firewall.EgressNAT.Enabled"
                                label={translate(`resources.firewallsV2.enableEgressNat`)}
                                dataMetrics={"cloudngfw-firewall-edit-egressNatEnabled"}
                                description={translate(`resources.firewallsV2.enableEgressNatMutedText`)}
                            />

                            {egressNatEnable && (
                                <>
                                    <Subtitle as={"div"} appearance={"semibold"} size={"sm"}>
                                        {translate(`resources.firewallsV2.ipAddressPool`)}
                                    </Subtitle>
                                    <RadioGroup
                                        name="Firewall.EgressNAT.Settings.IPPoolType"
                                        horizontal={true}
                                        items={awsServiceOptions?.map(awsService => ({
                                            value: awsService?.value,
                                            children: awsService?.label
                                        }))}
                                    />
                                    {iPoolType === 'BYOIP' && (
                                        <Input
                                            name="Firewall.EgressNAT.Settings.IPAMPoolId"
                                            label={translate(`resources.firewallsV2.ipAddressPool`)}
                                            placeholder="Please enter a IPAM pool id (e.g., ipam-pool-0123456789abcdef)"
                                            register={{ validate: (value) => isValidIPAMPoolId(value) }}
                                            error={errors.Firewall?.EgressNAT?.Settings?.IPAMPoolId?.message}
                                            dataMetrics="cloudngfw-firewall-create-egress-nat-public-own-ips"
                                        />
                                    )}
                                    {!isEmpty(formMethods.getValues("Status.PublicIPs")) && iPoolType === initialIPPoolTypeRef.current && (
                                        <AWSServiceIPsTableComponent
                                            value={formMethods.getValues("Status.PublicIPs")}
                                        />
                                    )}
                                </>
                            )}

                            <div className="tw-flex tw-justify-end">
                                <Button onClick={handleCancel} disabled={isEmpty(dirtyFields)} style={{ marginRight: '5px' }}>
                                    {translate(`generic.cancel`)}
                                </Button>
                                <SubmitButton type="submit" disabled={isEmpty(dirtyFields)}>
                                    {translate(`generic.save`)}
                                </SubmitButton>
                            </div>
                        </FormLayout>
                    </form>
                </FormProvider>
            </div>
        </div>
    );
};

export default FirewallEgressNat;
